import React, { useState, useEffect } from 'react';
import './Main.css';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { importAll } from '../Components/Helpers';
import Arrow from '../Components/CarouselArrow';

const req = require.context(
	'../Content',
	false,
	/Main-[0-9]+.(png|jpe?g|svg|JPG)$/
);
function getImages() {
	const images = importAll(req, true);
	let sortList = {};
	for (let key of Object.keys(images)) {
		const index = parseInt(key.split('.')[0].split('-')[1]);
		sortList[index] = images[key];
	}
	const out = Object.values(sortList);
	return out;
}

function Main() {
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		function resize() {
			setHeight(window.innerHeight);
		}

		window.addEventListener('resize', resize);

		return function cleanup() {
			window.removeEventListener('resize', resize);
		};
	}, [setHeight]);

	return (
		<div className="main">
			<Carousel
				renderArrowNext={(onClick, hasNext) => (
					<Arrow onClick={onClick} direction={'Right'} hasNext={hasNext} />
				)}
				renderArrowPrev={(onClick, hasPrev) => (
					<Arrow onClick={onClick} direction={'Left'} hasPrev={hasPrev} />
				)}
				className="carousel"
				infiniteLoop
				autoPlay
				swipeable
				interval={4000}
				dynamicHeight={false}
			>
				{getImages().map((val, index) => (
					<div key={index} className="carousel-img">
						<img
							className="main-img"
							height={height / 1.8}
							src={val}
							alt=""
						></img>
					</div>
				))}
			</Carousel>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						padding: '0px 40px',
					}}
				>
					<h1 className="ar info-title">نبذة عن قرية المشتاية</h1>
					<a href="#engBriefing">English Version</a>
				</div>
				<div
					className="info"
					style={{ padding: height > 800 ? '25px 40px' : '15px 20px' }}
				>
					<p className="ar">
						تلفظ : مِشتايَه – مِشتايْ – مِشتايَه , وتكتب مشتايه.
						<br />
						لفظ مشتايه يعني بالسريانية, أو الاشورية (ماء الشرب) فلفظ مشتايه مركب
						من مقطعين في لغتهما. المقطع الأول (مي) ويعني الماء, والمقطع الاخر
						(شتايه) ويعني الشرب, وجمع المقطعين معا (مي شتايه) وقلبت الياء كسرة,
						وبقي تداولها هكذا بلسان الأقوام القديمة منذ الأراميين... في منطقة
						تميزت بينابيع متعددة ومؤقتة, وفي بيئة تميزت بنشاطات تجارية
						وعسكرية... أكثر مما تميزت بنشاط زراعي, وربما متقدمة عليه لزمن أبعد
						أضفت ضروراتها استخدام هذا اللفظ (مشتايه) ماء الشرب حاجة حيوية عامة
						غير خاصة بأحد, فرضتها أهمية الموقع محطة بين مدن الساحل القريبة, ومدن
						الداخل البعيدة, واستمرت هذه العبارة مع الزمن تطلق على الموقع تقرأ
						مكتوبة بالعربية (مشتايه).
						<br />
						يتطابق هذا التفسير مع تعريف المشتايه الوارد في مصدر (الأصول
						السريانية في أسماء المدن, والقرى السورية) حيث وردت باسم (ماشتيو)
						وتعني الشرب (المرويون) بحالة الجمع.
						<br />
						لموقع المنطقة أهمية استراتيجية, من حيث انه يفصل جبال عكار عن الطرف
						الجنوبي لجبال اللاذقية وسط الممر الهام المعروف جغرافيا ب (فتحة حمص –
						طرابلس) ليصل ساحل البحر الأبيض المتوسط مع داخل بلاد الشام في القطر
						العربي السوري, على علو منخفض تتناهى فيه جبال اللاذقية الى مجموعة
						تلال وهضاب تكتنفها الأودية مختلفة الاتجاهات وسط الفتحة ذات الأهمية
						الجغرافية طبيعيا وتجاريا, وعسكريا.
						<br />
						في أحضان وادي العطشان تقوم قرية المشتاية الحالية على ارتفاع 467 متر
						فوق سطح البحر, غرب مدينة حمص و تبعد عنها 55 كم وتتوسط وادي النضارة
						,حيث تطل من الشرق على فتحة عين العجوز, تبدو منها كتلة جبل الحلو
						بينما تطل من الغرب على سهل, وشبه سهل عكار حتى البحر الأبيض المتوسط,
						حيث يمكن مشاهدة ميناء طرابلس وفناره بالعين المجردة مع حركة السفن
						والبواخر في عمق البحر أيام الرؤية الجيدة, عند الانقشاع الجيد فوق سطح
						البحر في لوحة بانورامية ( شاملة ) شدت اليها مخرجي الأفلام السينمائية
						و المسلسلات التلفزيونية و غيرها ... أظهروا منها في أعمالهم :<br />
						- نجمة الصبح للمخرج هيثم حقي.
						<br />
						- ردم الأساطير للمخرج هيثم حقي.
						<br />
						- صلاح الدين.
						<br />
						- زمان الوصل.
						<br />
						- هولاكو.
						<br />
						- بيازيد ( روسي ) للمخرج الروسي استانبولا.
						<br />
						- مرايا 2003 للمخرج سيف الدين سبيعي.
						<br />
						- فيديو كليب للفنانة ميادة بسيليس.
						<br />
						يحق لقرية المشتاية أن تفخر بهذه اللوحة الأخاذة التي حباها الله بها,
						امتدادا غير محدود لمظهر الغيوم تتراكم فوق الجبال, لا بل هي الجبال
						نفسها تتدحرج من عل الى الوديان ... بمشهد ساحر جميل يختلط أحيانا
						بأكوام الضباب الكثيف (الغطيطة) وهي تزحف من تحت الأحراش في قاع
						الوديان مع النسيم نحو القمم, لا تلبس أن تتلاشى قبل الوصول اليها أمام
						أشعة شمس الصباح بمشهد طبيعي ساحر, يوحي بالتأمل والاعجاب في هذه
						اللوحة الطبيعية الخالدة, تضم معا مآثر الطبيعة المتباينة و مآثر
						التاريخ الغابرة السياحية, الدينية, والأثرية ... يشهد على التنافس بين
						انجاز الطبيعة الخالد , و انجاز الانسان الحضاري في وادي العطشان,
						تنافس تجاوز بانجازاته حاجة وادي العطشان لأبعد منه عبر الزمان و
						المكان, داخل القطر و خارجه, مسخرا ما أوجدته الطبيعة مع ما أوجده
						الانسان من أجل حاجات عسكرية ( قلعة الحصن ...), و حاجات تجارية (طرق
						ووسائل نقل, ومحطات قوافل, و سوق المعرض ), وحاجات روحية ثقافية
						اجتماعية (دير القديس جاورجيوس الحميراء البطريركي, تكيته, كليته ... )
						وحاجات زراعية ( زيتون, توت, كرمة, تين ... ) ستبقى جميلة غنية بعين
						الأعداء قبل الغرباء .. !
					</p>
					<p className="writer" id="engBriefing">
						من كتاب ( المشتاية ذاكرة حضارية في سيرة وادي العطشان ) للأستاذ رامز
						الياس صليبي
					</p>
				</div>
				<h1 style={{ padding: '0px 40px' }} className="info-title">
					About the village
				</h1>
				<div
					className="info"
					style={{ padding: height > 800 ? '25px 40px' : '15px 20px' }}
				>
					<p>
						Pronunciation: Mishtaya - Mishtay - Meshtaye, and written Mishtaya.{' '}
						<br />
						The word Mishtaya means in Syriac, or Assyrian (drinking water), so
						the word Mishtaya is composed of two syllables in their language.
						The first syllable (Mi) means water, and the other syllable (Shtaya)
						means drinking, and the two syllables combined together (Mi Shtaya),
						and its circulation remained like this in the language of the
						ancient peoples since the Aramaic...in an area characterized by
						multiple and temporary springs, and in an environment characterized
						by commercial and military activities... more than it was
						characterized by agricultural activity, and perhaps it was ahead of
						it for a longer time. Its necessities added to the use of this term
						(Mishtaya) drinking water, a vital, public need not specific to
						anyone, imposed by the importance of the location as a station
						between the nearby coastal cities and the distant cities of the
						interior, and this phrase continued with time and was written in
						Arabic (Mishtaya).
						<br />
						This interpretation coincides with the definition of Al Mishtaya
						contained in the source (Syriac origins in the names of Syrian
						cities and villages), where it was mentioned in the name (Mashtiu)
						and means drinking (the Meroitic) in the plural case. An area
						characterized by multiple and temporary springs.
						<br />
						The location of the region is of strategic importance, it separates
						the Akkar Mountains from the southern edge of the Latakia Mountains
						in the middle of the important corridor known geographically as
						(Homs-Tripoli opening) to connect the Mediterranean coast with the
						interior of the Levant in the Syrian Arab country, at a low altitude
						in which the Latakia Mountains end to a group of hills and plateaus
						surrounded by valleys of different directions in the middle of the
						opening of geographical importance, naturally, commercially, and
						militarily.
						<br />
						In the arms of Wadi Al-Atshan, the current village of AlMishtaya is
						located at an altitude of 467 meters above sea level, west of the
						city of Homs and 55 km away from it. The Akkar plain reaches the
						Mediterranean, where the port of Tripoli and its lighthouse can be
						seen with the naked eye with the movement of ships and ships in the
						depths of the sea on good days of visibility, when the good solace
						above the seaU+2019s surface in a panoramic (comprehensive) panel
						drawn to it by the directors of films and TV series and others..{' '}
						<br />
						They showed them in their works:
						<br />
						• Star of the Morning, directed by Haitham Hakki.
						<br />
						• Bridging Myths, directed by Haitham Haqqi.
						<br />
						• Salahaddine .<br />
						• Connection time.
						<br />
						• Hulaku.
						<br />
						• Beyazid (Russian), directed by the Russian director Istanbula.
						<br />
						• Mirrors 2003, directed by Seif El-Din Subai'i.
						<br />
						• A video clip for the artist Mayada Basilis.
						<br />
						The village of AlMishtaya has the right to be proud of this
						breathtaking painting that God has endowed with it, an unlimited
						extension of the appearance of clouds that accumulate over the
						mountains, rather the mountains themselves roll from high to the
						valleys ... with a beautiful charming scene that sometimes mixes
						with piles of thick fog (Ghtayta) crawling from below the bushes at
						the bottom of the valleys with the breeze towards the peaks, then it
						fades before reaching in front of the morning sun rays with a
						charming natural scene, suggesting contemplation and admiration in
						this timeless natural painting, which includes together the various
						exploits of nature and the exploits of the ancient tourist,
						religious, and archaeological history... testifies to the
						competition between the immortal nature’s achievement, and the
						civilized man’s achievement in Wadi Al-Atshan, a competition that
						surpassed, through its achievements, the needs of Wadi Al-Atshan
						beyond it through time and space, inside and outside the country,
						harnessing what nature created with what man created for military
						needs ( The fortress ALHOSN...), commercial needs (roads and means
						of transportation, caravan stations, and the fair market),
						spiritual, cultural, and social needs (St. George al-Humayra
						Patriarchal Monastery, its hut, its college...) and agricultural
						needs (olive, berries, vine, fig...) It will remain beautiful and
						rich in the eyes of enemies before strangers..!
						<br />
					</p>
					<p className="writer">
						From the book (Al-Mashtaya, a Civilized Memory in the Biography of
						Wadi Atshan), by Professor Ramez Elias Slaiby
					</p>
				</div>
			</div>
		</div>
	);
}

export default Main;
