import React, { useState } from "react";
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    let nav = useNavigate();

    function handleLogin(e) {
        e.preventDefault();

        fetch("/auth/login", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            body: JSON.stringify({ username: username, password: password })
        })
            .then(res => {
                if (res.status === 200)
                    nav("../", {replace: true});
            });
    }

    return <div style={{ height: window.innerHeight, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
        <h1 style={{ paddingBottom: "100px" }}>Login to access the admin console</h1>
        <Form>
            <Form.Group className='mb-3' controlId='formName'>
                <Form.Label>Username:</Form.Label>
                <Form.Control
                    size='lg'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='Username'
                />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formName'>
                <Form.Label>Password:</Form.Label>
                <Form.Control
                    size='lg'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Password'
                    type="password"
                />
            </Form.Group>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button onClick={handleLogin}>Login</Button>
            </div>
        </Form>
    </div>;
}

export default Login;