import React, { useState, useEffect, useCallback } from 'react';
import './Media.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Modal } from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { importAll } from '../Components/Helpers';
import Arrow from '../Components/CarouselArrow';

function shiftArrUntilValue(array, value) {
	const arr = [...array];
	const index = arr.indexOf(value, 0);
	const length = arr.length;
	if (length / 2 >= index)
		while (arr[0] !== value) {
			const el = arr.shift();
			arr.push(el);
		}
	else
		while (arr[0] !== value) {
			const el = arr.pop();
			arr.unshift(el);
		}

	return arr;
}

const req = require.context('../Content', false, /\.(png|jpe?g|svg|JPG)$/);
const images = importAll(req);
const videos = importAll(require.context('../Content', false, /\.(mp4|mkv)$/));

function Media() {
	const [height, setHeight] = useState(window.innerHeight);
	const [width, setWidth] = useState(window.innerWidth);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [contentWidth, setContentWidth] = useState(450);
	const [gridTemplateAreas, setGridTemplateAreas] = useState(computeGridAreas);
	const [carouselArr, setCarouselArr] = useState([...images]);

	function computeGridAreas() {
		let nAreas = parseInt(width / (contentWidth + 15));
		let areas = [];
		for (let i = 1; i <= nAreas; i++) {
			areas.push('area');
		}
		return areas.join(' ');
	}

	const effectCGA = useCallback(computeGridAreas, [contentWidth, width]);

	useEffect(() => {
		function resize() {
			setWidth(window.innerWidth);
			setHeight(window.innerHeight);
			setGridTemplateAreas(effectCGA());
		}

		window.addEventListener('resize', resize);

		return function cleanup() {
			window.removeEventListener('resize', resize);
		};
	}, [setHeight, setGridTemplateAreas, effectCGA]);

	return (
		<div>
			<Modal
				fullscreen
				style={{ width: '100%', height: '100%' }}
				show={modalVisibility}
				onHide={() => setModalVisibility(false)}
			>
				<button
					style={{
						border: 'none',
						backgroundColor: 'white',
						borderRadius: '100px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '40px',
						height: '40px',
						marginBottom: '10px',
					}}
					onClick={() => setModalVisibility(false)}
				>
					<FontAwesomeIcon icon={faClose} size="1x" />
				</button>
				<Carousel
					renderArrowNext={(onClick, hasNext) => (
						<Arrow onClick={onClick} direction={'Right'} hasNext={hasNext} />
					)}
					renderArrowPrev={(onClick, hasPrev) => (
						<Arrow onClick={onClick} direction={'Left'} hasPrev={hasPrev} />
					)}
					style={{ width: '500px' }}
					className="carousel"
					dynamicHeight={false}
					infiniteLoop
				>
					{carouselArr.map((value, index) => (
						<div style={{height: height*.8}} key={`${index}-carousel`}>
							<img src={value} height="100%" alt="" className="main-img" />
						</div>
					))}
				</Carousel>
			</Modal>
			<h1 style={{ paddingLeft: '20px' }}>Pictures</h1>
			<a style={{ paddingLeft: '20px' }} href="#videos">
				Jump to videos
			</a>
			<div
				className="content grid"
				style={{ gridTemplateAreas: `"${gridTemplateAreas}"` }}
			>
				{images.length > 0 ? (
					images.map((value, index) => (
						<div
							key={index}
							className="item"
							style={{ width: contentWidth + 'px', height: 'auto' }}
						>
							<img
								alt=""
								src={value}
								width={contentWidth + 'px'}
								onClick={() => {
									setModalVisibility(true);
									setCarouselArr(shiftArrUntilValue(carouselArr, value));
								}}
							></img>
						</div>
					))
				) : (
					<h3>No pictures available at the moment.</h3>
				)}
			</div>
			<div id="videos" style={{ paddingBottom: '70px' }} />
			<h1 style={{ paddingLeft: '20px' }}>Videos</h1>
			<div className="content">
				{videos.length > 0 ? (
					videos.map((value, index) => (
						<div key={index} className="item">
							<video controls src={value}></video>
						</div>
					))
				) : (
					<h3>No videos available at the moment.</h3>
				)}
			</div>
		</div>
	);
}

export default Media;
