import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

function AdminBar() {
	const [dim, setDim] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		function handleResize() {
			setDim({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		window.addEventListener('resize', handleResize);

		return function cleanup() {
			window.removeEventListener('resize', handleResize);
		};
	}, [setDim]);

	return (
		<div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
			<div
				style={{ width: '20%', height: '100%', backgroundColor: 'black' }}
			></div>
			<div style={{ width: '80%', height: '100%' }}>
				<Outlet />
			</div>
		</div>
	);
}

export default AdminBar;
