import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import './ContactUs.css';

function ContactUs(props) {
	const location = useLocation()

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [success, setSuccess] = useState(false);

	function sendMsg() {
		if (name && email && message) {
			console.log(name, email, message);
			fetch('/contactus/api/send', {
				headers: { 'Content-Type': 'application/json' },
				method: 'POST',
				body: JSON.stringify({ name, email, message }),
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.msg === 'SUCCESS') {
						setSuccess(true);
						setTimeout(() => {
							setSuccess(false);
						}, 3000);
					} else alert("Something went wrong... Try again later.")
				});
		} else alert('All fields need to be filled');
	}

	if(success && location.pathname !== "/contactus")
		props.successHandler()

	return (
		<div
			style={{
				width: '100%',
				height: window.innerHeight * 0.8,
				paddingTop: '100px',
				display: 'flex',
				alignItems: 'center',
			}}>
			<Form>
				<Form.Group className='mb-3' controlId='formName'>
					<Form.Label>Enter your name</Form.Label>
					<Form.Control
						size='lg'
						value={name}
						onChange={(e) => setName(e.target.value)}
						placeholder='Name'
					/>
				</Form.Group>
				<Form.Group className='mb-3' controlId='formEmail'>
					<Form.Label>Enter your email</Form.Label>
					<Form.Control
						size='lg'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						type='email'
						placeholder='Email'
					/>
				</Form.Group>
				<Form.Group className='mb-3' controlId='formMsg'>
					<Form.Label>Enter your message</Form.Label>
					<Form.Control
						className='form-text-area'
						as='textarea'
						value={message}
						onChange={(e) => setMessage(e.target.value)}
						size='lg'
						htmlSize={'200px'}
						placeholder='Your message'></Form.Control>
				</Form.Group>
				<Button
					variant='primary'
					type='submit'
					onClick={(e) => {
						e.preventDefault();
						sendMsg();
					}}>
					Submit
				</Button>
			</Form>
			{success && location.pathname === "/contactus" ? <h1>Your message has been successfully sent</h1> : null}
		</div>
	);
}

export default ContactUs;
