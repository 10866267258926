import { useState, useEffect } from 'react';

function useDimensions() {
	const [dimensions, setDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		function resize() {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}

		document.addEventListener('resize', resize);

		return () => document.removeEventListener('resize', resize);
	}, [dimensions, setDimensions]);

	return dimensions;
}

export default useDimensions;
