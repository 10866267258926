import React, { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faC,
    faChevronLeft,
    faChevronRight
} from '@fortawesome/free-solid-svg-icons';

function Arrow(props) {
    useEffect(() => {
        function arrowKeyHandle(e) {
            if (props.direction === "Right" && e.key === "ArrowRight") props.onClick();
            if (props.direction === "Left" && e.key === "ArrowLeft") props.onClick();
        }

        document.addEventListener("keydown", arrowKeyHandle);

        return function cleanup() { document.removeEventListener("keydown", arrowKeyHandle); };
    });

    return (props.hasNext || props.hasPrev) && <button style={{ width: "50px", height: "50px", borderRadius: "100%", backgroundColor: "#2a3a6f", borderColor: "#2a3a6f", boxShadow: "none", position: "absolute", top: "50%", [props.direction === "Right" ? "right" : "left"]: 10, [props.direction === "Left" ? "zIndex" : ""]: 50 }} title={props.direction === "Right" ? "Next slide" : "Previous slide"} onClick={props.onClick}>
        <FontAwesomeIcon icon={props.direction === "Right" ? faChevronRight : faChevronLeft} color={"white"} />
    </button>;
}

export default Arrow;