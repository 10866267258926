import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { importAll } from '../Components/Helpers';
import Arrow from '../Components/CarouselArrow';

const req = require.context(
	'../Content',
	false,
	/\Deir-[0-9]+.(png|jpe?g|svg|JPG)$/
);

function getImages() {
	const images = importAll(req, true);
	let sortList = {};
	for (let key of Object.keys(images)) {
		const index = parseInt(key.split('.')[0].split("-")[1]);
		sortList[index] = images[key];
	}
	const out = Object.values(sortList);
	return out;
}

function Monastery() {
	const [height, setHeight] = useState(window.innerHeight);

	useEffect(() => {
		function resize() {
			setHeight(window.innerHeight);
		}

		window.addEventListener('resize', resize);

		return function cleanup() {
			window.removeEventListener('resize', resize);
		};
	}, [setHeight]);

	return (
		<div>
			<Carousel renderArrowNext={(onClick, hasNext) => (<Arrow onClick={onClick} direction={"Right"} hasNext={hasNext} />)}
				renderArrowPrev={(onClick, hasPrev) => (<Arrow onClick={onClick} direction={"Left"} hasPrev={hasPrev} />)}
				style={{ width: '500px' }}
				className='carousel'
				dynamicHeight
				autoPlay
				swipeable
				interval={3000}
				infiniteLoop>
				{getImages().map((value, index) => (
					<div className='carousel-img' key={`${index}-carousel`}>
						<img src={value} height='100%' alt='' className='main-img' />
					</div>
				))}
			</Carousel>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					padding: '0px 40px',
				}}>
				<h1 className='ar info-title'>
					دير القديس جاورجيوس الحميراء البطريركي
				</h1>
				<a href='#engBriefing'>English Version</a>
			</div>
			<div
				className='info'
				style={{ padding: height > 800 ? '25px 40px' : '15px 20px' }}>
				<p className='ar'>
					التسمية:
					<br />
					دير: تطلق كلمة دير على تجمع قليل لبيوت سكن مدني كما هو شائع في :<br />
					دير الزور - دير بعلبة - دير حافر....... أو على سكن رهبان : دير السيدة
					.دير مارالياس ودير مارجرجس...... <br />
					خضر: وتعني منقذ أرض أو منقذ شعب , أسطورة قبل المسيحية ولها معنى حيدر
					في السيريانية وعامل أرض في اليونانية (جيو أرجيوس) <br />
					مار: تعني مدبرا له صفة التعظيم ,أو السيد ولا تعني التقديس في
					السيريانية والعربية . <br />
					جرجس : اسم ذاع مع انتشار المسيحية تردده الذاكرة الشعبية بألفاظ : جريس
					–جرجيس- جرج-جريج...... عربيا وغير عربي بأسماء :جورج – جاورجيوس- جورجي
					– جوركي - جورجيس ....... اسم لرجل أسطوري يمثل دور المنقذ في أسطورة
					التنين والوحش, والأضحية الإنسان الخطايا. <br />
					الحميراء : يرجح أنها نسبة إلى موقع سكني قديم بهذا الاسم , لم يبق منه
					سوى أنقاض وحجارة وأعمدة تحت التراب قام الدير بجانبها وحمل هذا الاسم ,
					أيضا لا تزال حتى اليوم قطعة أرض تحمل اسم (الحميره) في ناحية القرية
					الجنوبية قرب بئر ماء القرية الضمني .وقد عرف الدير بأسماء عدة: <br />
				</p>
				<Table
					style={{ width: '50%', height: 'auto', alignSelf: 'flex-end' }}
					bordered
					striped>
					<tbody>
						<tr>
							<td>حيث أطلق عليه هذا الاسم قديما في أنحاء سورية</td>
							<td>الدير الحميري- دير الحميرا</td>
						</tr>
						<tr>
							<td>كما يعرفه سكان المناطق الشرقية</td>
							<td>الخضر الغربي - الخضر</td>
						</tr>
						<tr>
							<td>كما يعرف في قرى المنطقة</td>
							<td>دير مارجرجس</td>
						</tr>
						<tr>
							<td>كما يعرف في المنطقة والبلدان البعيدة</td>
							<td>دير مارجرجس الحميراء</td>
						</tr>
						<tr>
							<td>كما هو معرف في مراسلاته الرسمية</td>
							<td>دير القديس جاورجيوس الحميراء البطريركي </td>
						</tr>
						<tr>
							<td>كما هو معرف في لوحة مدخله الخارجي مؤخرا</td>
							<td>القديس جاورجيوس</td>
						</tr>
					</tbody>
				</Table>
				<p className='ar'>
					إن إيواء المارة واستقبال الزوار, جعله محطة على الطريق القديم , حفاظا
					على حركة المرور وسلامتها و دفعا للراحة أو منعا للسطو يوما كانت اللعنة
					تردع , ومقاما مشتركا يؤدي خدمات روحية ومادية دون استثناء .<br />
					حرص القيمون قليه على إيواء المارة والزوار حيث حث الإسلام عليها وتماشيا
					مع إرادة أبناء الشعب بهذا الشأن جعله ينال العطاء والارتفاق بالنذور
					التي كمان ينادى له بها في المناطق القريبة ,والبعيدة تكية للمارة محطة
					استراحة لخليط مسافرين ,والزوار وتجار مختلف الممالك والدول
					الداخلية....... مما يفهم بأن الطريق قد ساهم في فرض موقعه وتطوره إلا أن
					قانون التطور وأحكام التغيير في سنّة النشوء والارتقاء للعلاقات العامة
					أملت على الطريق أيضا تغيير مواقعه نراها في تغيير بواباته ما يحسم تواتر
					الروايات فيه :<br />
					1- بوابة جنوبية على الطريق القديم سدت بعد بناء الخان الكبير .<br />
					2- بوابة غربية بديل السابقة على شكل قنطرة نزعت منها لوحتها الشاهدة
					.......وعلى ما يبدو أعيد تصغيرها على مقياس البوابة الجنوبية السابقة
					,وباب حجر البازلت أطلق عليها متسلمو الدير (البوابة المقدسة).
					<br />
					3- باب شرقي وهو المدخل الرئيسي الحالي للدير يحتوي على بوابة صغيرة
					أقيمت عام 1888 م بعد قيام الكنيسة المحدثة حيث صار الطريق يمر بين
					الكنيسة المحدثة وسوق المعرض إلى الفوار, ما لبث أن حول بوصلتين من
					الطريق العام للقرية من أمام باب السوق الشرقي والثانية من أمام باب
					السوق الغربي.
					<br />
					4- بوابة شرقية تحت سوق المعرض قطع طريق الفوار السابق بعد أن اشتق له
					طريق عند موقع اللقية محاذيا مقبرة القرية حتى يلتقي مع الطريق العام
					مقابل مبنى صالة الجمعية والبلدية ....
					<br />
					يوجد ثلاثة أبواب في جدار السور الغربي القديم على طريق الفوار والبرك
					والبساتين ,يفضون إلى الباحة الغربية أغلق منها بابان ,كما يوجد في جدار
					السوق الشرقي الحديث أيضا ثلاثة أبواب على الطريق العام يفضون إلى الباحة
					الشرقية ..وبوابة تصل الباحة الداخلية مع الباحة الغربية تسهيلا لحركة
					المرور خلال الازدحام الشديد خاصة في المناسبات المعروفة عيد الدير وعيد
					الصليب ,عززت دوره مركزا روحيا وطنيا رسخ قيمة الإنسان غاية لا وسيلة في
					خدمة الإيمان بالله ,لاقى في ظله من العطاء والهدايا ما يسبق تاريخ قيامه
					أو معاصرته او بعده فإن كان الزمن بمفارقاته جعلنا نرى من قرية المشتاية
					معلما أثريا هو قلعة الحصن دفن فيها الظاهر بيبرس شرور التعصب الديني
					الذي يؤججه الدخيل عادة .....فإنه أتاح أيضا أن نرى في القرية دير
					مارجرجس الحميراء الذي حفظ مخزونا دينيا كبيرا في المحبة والوئام والتآخي
					بين مختلف الانتماءات الدينية وطوائفها في ربوع المنطقة وخارجها أفرادا
					ومسؤولين روحيين وزمنيين بمشاعر إنسانية يشهد له فيها منذ قيامه قبل مئات
					السنين في أعياده ,لم يتخلف أحد من مسلمين ومسيحيين ....
					<br />
				</p>
				<p className='writer' id='engBriefing'>
					من كتاب ( المشتاية ذاكرة حضارية في سيرة وادي العطشان ) للأستاذ رامز
					الياس صليبي 2004
				</p>
			</div>
			<h1 className='info-title' style={{ padding: '0px 40px' }}>
				Saint George's Patriarchal Monastery of Hamira
			</h1>
			<div
				className='info'
				style={{ padding: height > 800 ? '25px 40px' : '15px 20px' }}>
				<p>
					Naming:
					<br />
					Deir: The word monastery refers to a small tear in the houses of
					civilian housing, as is common in Deir ez-Zor - Deir Baalba - Deir
					Hafer.......or on the residence of monks: the monastery of the Lady,
					the monastery of Mar Elias and the monastery of St. George......
					<br />
					Khader: It means savior of a land or savior of a people. It is a
					pre-Christian myth and has the meaning of Haider in Syriac and land
					worker in Greek (Geo-Argios). <br />
					Mar: means a ruler who has the attribute of glorification, or the
					master. It does not mean sanctification in Syriac and Arabic. <br />
					Gerges: a name that became popular with the spread of Christianity,
					and it was repeated by the popular memory with the words: Jeries -
					Gerges - Gerge - Jreige...... Arabic and non-Arabic with the names:
					George - Gawergios - Georgian - Gorky - Georgys ....... the name of a
					legendary man representing The role of the savior in the legend of the
					dragon and the beast, the sacrifice of human sins. <br />
					Al-Humaira: It is likely that it is related to an old residential site
					with this name, from which nothing remains except ruins, stones and
					pillars under the soil. The monastery was known by several names:{' '}
					<br />
				</p>
				<Table
					style={{
						width: '50%',
						height: 'auto',
						paddingLeft: '30px',
					}}
					striped
					bordered>
					<tbody>
						<tr>
							<td>Where this name was called in the past throughout Syria</td>
							<td>Al-Deir Al-Humairi </td>
						</tr>
						<tr>
							<td>As the people of the eastern regions know it</td>
							<td>Al-Khader Al-Gharbi</td>
						</tr>
						<tr>
							<td>As it is known in the villages of the region</td>
							<td>Saint George Monastery</td>
						</tr>
						<tr>
							<td>As it is known in the region and distant countries</td>
							<td>St. George's Monastery Al-Humaira</td>
						</tr>
						<tr>
							<td>As defined in his official correspondence</td>
							<td>St. George Hamira Patriarchal Monastery</td>
						</tr>
						<tr>
							<td>As defined in his recent exterior entrance plaque</td>
							<td>St. “Gawergios”</td>
						</tr>
					</tbody>
				</Table>
				<p>
					Accommodating passers-by and receiving visitors made it a stop on the
					old road, in order to preserve the traffic and its safety and to pay
					for comfort or to prevent robbery. One day the curse was deterred, and
					a common place that performs spiritual and material services without
					exception. <br />
					The curators were keen to accommodate passers-by and visitors, as
					Islam urged it, and in line with the will of the people in this
					regard, it made him obtain giving and ease in the vows that he also
					called to him in the nearby and far areas, a hospice for passers-by, a
					rest stop for a mixture of travelers, visitors and merchants of
					different kingdoms and internal countries.... ... From what is
					understood that the road has contributed to the imposition of its
					position and its development, but the law of development and the
					provisions of change in the year of emergence and advancement of
					public relations dictated on the road also the change of its
					locations. <br />
					1- A southern gate on the old road was blocked after the construction
					of the Great Khan. <br />
					2- An alternate western gate in the form of an arch, from which its
					stele plate was removed. It appears that it was re-minimised according
					to the scale of the previous southern gate, and the basalt stone gate
					was called by the monastery’s recipients (the holy gate). <br />
					3- Bab Sharqi, which is the current main entrance to the monastery,
					and it contains a small gate that was built in 1888 AD after the
					establishment of the modernized church, as the road passed between the
					modernized church and the exhibition market to al-Fawwar. Western
					Market Gate. <br />
					4- An eastern gate under the exhibition market cut off the previous
					Al-Fawwar road after a road was made for it at the Lakiya site,
					adjacent to the village cemetery, until it meets with the main road
					opposite the building of the Assembly and Municipal Hall.... <br />
					There are three gates in the wall of the old western wall on the road
					to al-Fawwar, al-Barak and al-Basateen, leading to the western
					courtyard, two of which are closed. In the modern eastern market wall
					there are also three gates on the main road leading to the eastern
					courtyard..and a gate connecting the inner courtyard with the western
					courtyard to facilitate movement Traffic during severe crowding,
					especially on the well-known occasions, the Convent’s Day and the
					Feast of the Cross. Its role was reinforced as a national spiritual
					center that established the value of the human being as an end rather
					than a means in serving faith in God. Al-Mashtaya village is an
					archaeological landmark, the castle of the fort, in which Al-Zahir
					Baybars was buried, the evils of religious fanaticism, which the
					intruder usually fuels..... Outside of it, individuals and spiritual
					and temporal officials with human feelings bear witness to him since
					his establishment hundreds of years ago on his feasts, not one of the
					Muslims and Christians has been left behind....
				</p>
				<p className='writer'>
					From the book (Al-Mashtaya, a Civilized Memory in the Biography of
					Wadi Atshan), by Professor Ramez Elias Slaiby 2004
				</p>
			</div>
		</div>
	);
}

export default Monastery;
