import React from 'react';
import './Wildcard.css';

function Wildcard() {
	return (
		<div className='wildcard' style={{ height: window.innerHeight }}>
			<h1>Page not found!</h1>
		</div>
	);
}

export default Wildcard;
