import React from 'react';
import './Bottom.css';
import useDimensions from '../hooks/dimensions';

function Bottom() {
	const dimensions = useDimensions();
	console.log(dimensions);
	return (
		<div style={{ margin: 0 }}>
			<style>
				{`a {
					${dimensions.width < 445 ? 'font-size: 14px !important' : ''}
				}`}
			</style>
			<div className='bottom'>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						width: '100%',
					}}>
					<div>
						<h1 className='bottom-header'>روابط فعاليات في المشتاية</h1>
						<h1 className='bottom-header'>Related Links to AlMishtaya</h1>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						width: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<ul className='bottom-links-main'>
						<li>
							<ul className='bottom-links'>
								<li>
									<a
										style={{
											textWrap: 'none',
											color: 'white',
											textDecoration: 'underline',
										}}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/groups/850460378431809/?ref=share'>
										جمعية إنعاش المشتاية
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='http://www.stgeorgesalhomeyra.org/'>
										دير القديس جاورجيوس الحميراء البطريركي
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/almshtai/'>
										المركز الثقافي في المشتايه
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/Mishtayh-Charity-Organization-250587508622218/'>
										جمعية إنعاش المشتاية - أمريكا
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/nadaslaibinadaslaibi/'>
										محترف حَنّه
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/groups/278672559635395/?ref=share'>
										المشتاية حياتي
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/mishtaya.scout/'>
										كشّاف المشتاية دير مارجرجس
									</a>
								</li>
							</ul>
						</li>
						<li>
							<ul className='bottom-links'>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/104506294229687/posts/626433775370267/'>
										أسبوع النظافة
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferer'
										href='https://www.facebook.com/groups/1725158324459137/?ref=share'>
										أخوية القديسة كاترينا
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/MahaadAlsalam/'>
										معهد السلام للغات
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/alwadi.hotel.456/'>
										فندق الوادي
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://fb.watch/e2sYkOxynX/'>
										Sayf AlWadi
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://fb.watch/e2t5djxX2p/'>
										Zorba Club
									</a>
								</li>
								<li>
									<a
										style={{ color: 'white', textDecoration: 'underline' }}
										target='_blank'
										rel='noreferrer'
										href='https://www.facebook.com/wadi.alnasara.lovers/'>
										صفحة محبي وادي النصارى
									</a>
								</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<div
				style={{
					backgroundColor: '#2a3a6f',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
				}}>
				<p
					style={{
						margin: 0,
						paddingBottom: '10px',
						color: 'aliceblue',
						alignSelf: 'center',
						fontSize: '18px',
					}}>
					Designed and programmed by Jason Ghantous
				</p>
			</div>
		</div>
	);
}

export default Bottom;
