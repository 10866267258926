function importAll(r, alt = false) {
	let images = {};
	let out = [];
	r.keys().map((item) => {
		images[item.replace('./', '')] = r(item);
	});
	if (alt) return images;
	for (const [key, value] of Object.entries(images)) {
		out.push(value);
	}

	return out;
}

export { importAll };
