import React, { useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import './TopBar.css';
import Logo from '../Content/logo.png';

import Marquee from './Marquee';

import Bottom from './Bottom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChurch,
	faHotel,
	faCoffee,
	faTree,
	faVideo,
	faCalendarAlt,
	faPerson,
	faBars,
	faSun,
	faCloud,
	faCloudRain,
	faCloudShowersHeavy,
	faSnowflake,
	faCloudBolt,
} from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactUs from '../Views/ContactUs';

function DropDownNav(props) {
	function toggleDropdown() {
		props.setOpenState((prev) => !prev);
	}

	return (
		<div>
			<style>
				{`
                .toggle {
					width: "auto";
                    color: aliceblue;
                    background-color: aliceblue;
                    border-color: #2a3a6f;
					justify-content: "center";
					align-items: "center";
                }
                .toggle:hover, .btn-primary.active, .btn-primary:active, {
					background-color: #2a3a6f;
                }
                .dropdown-toggle:hover > .fa-2x, .btn-check:active+.btn-primary > .fa-2x, .btn-check:checked+.btn-primary > .fa-2x, .btn-primary.active > .fa-2x, .btn-primary:active > .fa-2x, .show>.btn-primary.dropdown-toggle > .fa-2x {
                    color: aliceblue;
                }
                .dropdown-menu {
                    width: 350px;
                    height: auto;
					display: ${props.openState ? 'block' : 'none'};
                }
                .dropdown-menu > li {
                    display: flex;
                    justify-content: space-around
                }
                `}
			</style>
			<button
				className='btn btn-primary toggle'
				onClick={() => toggleDropdown()}>
				<FontAwesomeIcon style={{ padding: 0 }} icon={faBars} size='2x' />
			</button>
			{props.openState ? (
				<ul
					style={{
						position: 'absolute',
						left: '50%',
						marginTop: '25px',
						transform: 'translate(-50%, 0%)',
					}}
					className='dropdown-menu dropdown-menu-end'>
					<li>
						<Link
							className='link'
							to='/monastery'
							onClick={() => toggleDropdown()}>
							دير مار جرجس
						</Link>
						<Link
							className='link'
							to='/monastery'
							onClick={() => toggleDropdown()}>
							St. Georges monastery
						</Link>
					</li>
					<Dropdown.Divider />
					<li>
						<Link
							className='link'
							to='/hotels'
							onClick={() => toggleDropdown()}>
							الفنادق
						</Link>
						<Link
							className='link'
							to='/hotels'
							onClick={() => toggleDropdown()}>
							Hotels
						</Link>
					</li>
					<Dropdown.Divider />
					<li>
						<Link
							className='link'
							to='/restaurants'
							onClick={() => toggleDropdown()}>
							المطاعم
						</Link>
						<Link
							className='link'
							to='/restaurants'
							onClick={() => toggleDropdown()}>
							Restaurants
						</Link>
					</li>
					<Dropdown.Divider />
					<li>
						<Link
							className='link'
							to='/nature'
							onClick={() => toggleDropdown()}>
							الطبيعة
						</Link>
						<Link
							className='link'
							to='/nature'
							onClick={() => toggleDropdown()}>
							Nature
						</Link>
					</li>
					<Dropdown.Divider />
					<li>
						<Link
							className='link'
							to='/events'
							onClick={() => toggleDropdown()}>
							الفعاليات
						</Link>
						<Link
							className='link'
							to='/events'
							onClick={() => toggleDropdown()}>
							Events
						</Link>
					</li>
					<Dropdown.Divider />
					<li>
						<Link
							className='link'
							to='/people'
							onClick={() => toggleDropdown()}>
							شخصيات من القرية
						</Link>
						<Link
							className='link'
							to='/people'
							onClick={() => toggleDropdown()}>
							Important people
						</Link>
					</li>
					<Dropdown.Divider />
					<li>
						<Link className='link' to='/media' onClick={() => toggleDropdown()}>
							الإعلام
						</Link>
						<Link className='link' to='/media' onClick={() => toggleDropdown()}>
							Media
						</Link>
					</li>
				</ul>
			) : null}
		</div>
	);
}

function NavBar() {
	return (
		<nav className='navigation'>
			<div>
				<FontAwesomeIcon icon={faChurch} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/monastery'>
						دير مار جرجس
					</Link>
					<Link className='link' to='/monastery'>
						St. Georges monastery
					</Link>
				</div>
			</div>
			<div className='divider' />
			<div>
				<FontAwesomeIcon icon={faHotel} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/hotels'>
						الفنادق
					</Link>
					<Link className='link' to='/hotels'>
						Hotels
					</Link>
				</div>
			</div>
			<div className='divider' />
			<div>
				<FontAwesomeIcon icon={faCoffee} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/restaurants'>
						المطاعم
					</Link>
					<Link className='link' to='/restaurants'>
						Restaurants
					</Link>
				</div>
			</div>
			<div className='divider' />
			<div>
				<FontAwesomeIcon icon={faTree} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/nature'>
						الطبيعة
					</Link>
					<Link className='link' to='/nature'>
						Nature
					</Link>
				</div>
			</div>
			<div className='divider' />
			<div>
				<FontAwesomeIcon icon={faCalendarAlt} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/events'>
						الفعاليات
					</Link>
					<Link className='link' to='/events'>
						Events
					</Link>
				</div>
			</div>
			<div className='divider' />
			<div>
				<FontAwesomeIcon icon={faPerson} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/people'>
						شخصيات من القرية
					</Link>
					<Link className='link' to='/people'>
						Important people
					</Link>
				</div>
			</div>
			<div className='divider' />
			<div>
				<FontAwesomeIcon icon={faVideo} size='3x' />
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Link className='link' to='/media'>
						الإعلام
					</Link>
					<Link className='link' to='/media'>
						Media
					</Link>
				</div>
			</div>
		</nav>
	);
}

function TopBar() {
	const [width, setWidth] = useState(window.innerWidth);
	const [openState, setOpenState] = useState(false);
	const [temp, setTemp] = useState({});
	const [marquee, setMarquee] = useState({ en: [], ar: [] });
	const [showContact, setShowContact] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const nav = useNavigate();

	useEffect(() => {
		function resizeHandler() {
			setWidth(window.innerWidth);
		}

		fetch('/data/marquee', { method: 'GET', mode: 'cors' })
			.then((res) => res.json())
			.then((data) => {
				for (let lang in data) {
					const startLen = +`${data[lang].length}`;
					for (let i in data[lang])
						if (+i !== startLen - 1)
							data[lang].splice(
								i + 1,
								0,
								<span key={i + lang + 'sep'} style={{ fontWeight: 'bold' }}>
									{' '}
									|{' '}
								</span>
							);
				}
				setMarquee(data);
			});

		fetch('/data/weather', { method: 'GET', mode: 'cors' })
			.then((res) => res.json())
			.then((data) => {
				setTemp(data);
			});

		window.addEventListener('resize', resizeHandler);

		setTimeout(() => {
			if (width >= 1600) setShowContact(true);
		}, 4000);

		return function cleanup() {
			window.removeEventListener('resize', resizeHandler);
		};
	}, [setWidth, width]);

	function contact() {
		if (width >= 1600) {
			setShowContact((prev) => !prev);
			setExpanded(false);
		} else {
			nav('/contactus');
		}
	}
	function contactAction() {
		if(width >= 1600) {
			setExpanded(true)
		} else {
			nav('/contactus')
		}
	}

	// TODO: Set marquee to new phrases
	// MARQUEE ADJUSTMENTS:
	// Check the Event page to see day 4 photos for "Esbou3 El Nazafa".
	//تحقق من صفحة الفعالية لمشاهدة صور اليوم الرابع لأسبوع النظافة

	return (
		<div className='body'>
			<div className='topBar'>
				{width >= 1600 ? (
					<NavBar />
				) : (
					<DropDownNav openState={openState} setOpenState={setOpenState} />
				)}
				<Link to='/'>
					<img className='img-logo' src={Logo} alt='Almishtaya Logo'></img>
				</Link>
			</div>
			<div style={{ height: '5px' }}></div>
			<div
				style={{
					width: '100%',
					height: '18px',
				}}>
				<Marquee direction='Right' speed={0.2}>
					<div
						style={{
							flexDirection: 'column',
							height: '18px',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<p
							style={{
								height: '18px',
								fontSize: '18px',
							}}>
							{marquee.ar.map((val) => val)}
						</p>
					</div>
				</Marquee>
			</div>
			<div
				style={{
					width: '100%',
					borderBottom: '1px solid #2a3a6f',
					marginBottom: '10px',
					marginTop: '10px',
				}}></div>
			<div style={{ width: '100%', height: '18px' }}>
				<Marquee style={{}} direction='Left' speed={0.2}>
					<div
						style={{
							height: '18px',
							textAlign: 'center',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<p style={{ height: '18px', fontSize: '18px', paddingTop: '1px' }}>
							{marquee.en.map((val) => val)}
						</p>
					</div>
				</Marquee>
			</div>
			<div
				style={{
					width: '100%',
					borderBottom: '1px solid #2a3a6f',
					marginBottom: '10px',
				}}></div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
				}}>
				<h3 style={{ paddingRight: '10px' }}>{temp.temp}°C</h3>
				<FontAwesomeIcon
					style={{
						color:
							temp.wtype === 'Clear'
								? '#f5c922'
								: temp.wtype === 'Rain'
								? '#2a3a6f'
								: temp.wtype === 'Snow'
								? '#2a3a6f'
								: temp.wtype === 'Clouds'
								? '#2a3a6f'
								: temp.wtype === 'Drizzle'
								? '#2a3a6f'
								: temp.wtype === 'Thunderstorm'
								? '#2a3a6f'
								: 'white',
					}}
					size='2x'
					icon={
						temp.wtype === 'Clear'
							? faSun
							: temp.wtype === 'Clouds'
							? faCloud
							: temp.wtype === 'Drizzle'
							? faCloudRain
							: temp.wtype === 'Rain'
							? faCloudShowersHeavy
							: temp.wtype === 'Snow'
							? faSnowflake
							: temp.wtype === 'Thunderstorm'
							? faCloudBolt
							: null
					}
				/>
				<h3>الطقس في المشتاية</h3>
			</div>
			<div onClick={() => setOpenState(false)} className='outlet'>
				<Outlet />
			</div>
			{window.location.pathname !== '/contactus' ? (
				<button className='floating' onClick={contact}>
					<h2
						style={{
							fontSize: '22px',
						}}>
						{(
							window.navigator.userLanguage || window.navigator.language
						).startsWith('ar')
							? 'اتصل بنا'
							: 'Contact Us'}
					</h2>
				</button>
			) : null}
			{showContact && window.location.pathname !== '/contactus' ? (
				<div className={`floating-modal ${expanded ? "floating-modal-expand" : ""}`}>
					<h1>
						{(
							window.navigator.userLanguage || window.navigator.language
						).startsWith('ar')
							? 'اتصل بنا'
							: 'Contact Us'}
					</h1>
					{!expanded ? ( <>
						<label>Enter your name</label>
						<input placeholder='Name' onFocus={contactAction} />
					</>) : (
						<ContactUs successHandler={() => {
							contact()
							alert("Your message has been successfully sent");
						}} />
					)
					}
				</div>
			) : null}
			<Bottom />
		</div>
	);
}

export default TopBar;
