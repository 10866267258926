import React from "react";

function Admin() {
    // TODO: Add all contactus messages as a menu here
    // TODO: Add hits counter as a menu here
    // TODO: Add set marquee form as a menu here

    return <h1>Admin</h1>;
}

export default Admin;