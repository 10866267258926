import React from 'react';
import './Marquee.css';

function Marquee(props) {
	return (
		<section style={{ overflow: 'hidden' }}>
			<div>
				<section
					className="marquee"
					style={{
						animation: `${props.speed ** -1 * 5}s linear infinite ${
							props.direction === 'Left' ? 'scrollL' : 'scrollR'
						}`,
					}}
				>
					{props.children}
				</section>
			</div>
		</section>
	);
}

export default Marquee;
