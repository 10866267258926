import React from 'react';
import './Events.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { importAll } from '../Components/Helpers';
import Arrow from '../Components/CarouselArrow';

import EventPic1 from '../Content/Temp/EventPic1.jpeg';

const req1 = require.context(
	'../Content',
	false,
	/Jamiyeh[0-9]+.(png|jpe?g|svg|JPG)$/
);
function getImagesCat1() {
	const images = importAll(req1, true);
	let sortList = {};
	for (let key of Object.keys(images)) {
		const index = parseInt(key.split('.')[0].substring(7));
		sortList[index] = images[key];
	}
	const out = Object.values(sortList);
	return out;
}
const req2 = require.context(
	'../Content',
	false,
	/Katrina[0-9]+.(png|jpe?g|svg|JPG)$/
);
function getImagesCat2() {
	const images = importAll(req2, true);
	let sortList = {};
	for (let key of Object.keys(images)) {
		const index = parseInt(key.split('.')[0].substring(7));
		sortList[index] = images[key];
	}
	const out = Object.values(sortList);
	return out;
}
const req3 = require.context(
	'../Content',
	false,
	/CleaningWeek[0-9]+.(png|jpe?g|svg|JPG)$/
);
function getImagesCat3() {
	const images = importAll(req3, true);
	let sortList = {};
	for (let key of Object.keys(images)) {
		const index = parseInt(key.split('.')[0].substring(12));
		sortList[index] = images[key];
	}
	const out = Object.values(sortList);
	return out;
}

function Events() {
	return (
		<div style={{ display: 'flex' }}>
			<div className='toc'>
				<h2 style={{ whiteSpace: 'nowrap', alignSelf: 'center' }}>
					جدول المحتويات | Table of content
				</h2>
				<ul
					className='toc-table'
					style={{
						marginTop: '15px',
						paddingBottom: '40px',
						width: '500px',
						alignSelf: 'center',
					}}>
					<li>
						<a href='#cat1'>جمعية إنعاش الريف | Jamiat Inaash el Riff</a>
					</li>
					<li>
						<a href='#cat2'>أخوية القديسة كاترينا</a>
					</li>
					<li>
						<a href='#cat3'>برنامج مهرجانات القلعة والوادي ٢٠٢٢</a>
					</li>
					<li>
						<a href='#cat4'>أسبوع النظافة ٢٠٢٢</a>
					</li>
				</ul>
				<div id='cat1'>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '0px 40px',
						}}>
						<h1
							className='ar info-title'
							style={{ alignSelf: 'center', textDecoration: '' }}>
							جمعية إنعاش الريف
						</h1>
						<a href='#cat1Eng'>English Version</a>
					</div>
					<Carousel
						renderArrowNext={(onClick, hasNext) => (
							<Arrow onClick={onClick} direction={'Right'} hasNext={hasNext} />
						)}
						renderArrowPrev={(onClick, hasPrev) => (
							<Arrow onClick={onClick} direction={'Left'} hasPrev={hasPrev} />
						)}
						style={{ width: '500px' }}
						className='carousel'
						dynamicHeight
						autoPlay
						swipeable
						interval={3000}
						infiniteLoop>
						{getImagesCat1().map((value, index) => (
							<div className='carousel-img' key={`${index}-carousel`}>
								<img src={value} height='100%' alt='' className='main-img' />
							</div>
						))}
					</Carousel>
					<div className='info'>
						<p className='ar'>
							مارست القرية منذ وجودها كل جهاد في سبيل النفع العام , أو لمنفعة
							الغير بدون مقابل سواء أكان عملاً فردياً خاصاً أم اعتبارياً , فإن
							ذهب بعضه بفعل الزمن فبعضه الآخر بقي محفوظاً في الذاكرة الشعبية
							يمارس عملاً مستوراً شأن العمل الخيري وما هو معلن تفاخر به بين
							الأجيال حيث أدى دوره الإنساني كهبات وقفية أو تبرعات عينية دعماً
							لنشاط صحي أو ثقافي أو نداء سجين حر أو عون ضعيف منكوب.
							<br />
							إن عادة المساندة وأعمال العون المباشرة لأصحاب الحاجة الموروثة في
							القرية دفعت عفوياً قيام لجان خيرية متعاقبة منذ بدايات القرن الماضي
							مع أبناء القرية مقيمين ومغتربين وغيورين مثلو جنوداً مجهولين في هذا
							المجال ساهمت جهودهم في ميادين الخير لايزال يشهد لها:
							<br />
							- بناء المدرسة الابتدائية عام 1948 م .<br />
							- شق وتعبيد طرقات وشوارع القرية عن طريق العمل الشعبي عام 1960م.
							<br />
							- في عام 1965م بادر أبناء القرية المقيمون في القرية والمدينة إلى
							تأسيس جمعية خيرية شهرت باسم : جمعية إنعاش الريف في قرية المشتاية
							(خيرية, اجتماعية, عمرانية, ثقافية....)تحت رقم 819 تاريخ 24/1/1965م
							صادر عن وزارة الشؤون الاجتماعية والعمل مركزها قرية المشتاية تضم
							جمعية عمومية85 عضواً ومجلس إدارة منتخب مكون من : رئيس ,نائب مدير
							,أمين سر ,أمين صندوق, ثلاثة أعضاء.
							<br />
							ترتبط مع نشاط خيري يقوم به المغتربون من أبناء القرية في أميركا
							وأستراليا وغيرها من الأمصار بمشاريع خيرية عديدة غلب عليها الطابع
							العمراني الخدمي من منجزاتها:
							<br />
							- تشرف على إصدار تقويم سنوي خاص باسم الجمعية تبرز فيه معالم القرية
							ودورها التعاوني.
							<br />
							- تتطلع إلى دور طموح في ربط أبناء القرية بعضهم إلى بعض بالوطن
							مقيمين ومغتربين وتحصين تراثهم الوطني الإنساني.
							<br />
							- تقوم بنشاطات ثقافية واجتماعية متنوعة يمكن رصدها في ميزانيتها
							الختامية التي تصدق رسمياً كل عام من قبل محاسب قانوني وفق النموذج
							المرفق.
							<br />
							- بناء المدرسة الإعدادية عام 1970 م ( حالياً مبنى بلدية ,مستوصف
							,فرن , صالة مدرسة ,حضانة ....) - بناء سور ومظلة المقبرة في القرية.
							<br id='cat1Eng' />
							- تشارك وتساهم في أسبوع نظافة الذي يقيمه أبناء القرية في الأول من
							آب من كل عام وهو بمثابة أسبوع البيئة.
							<br />
						</p>
					</div>
					<div className='info'>
						<h1 className='info-title'>Jamiat Inaash el Riff</h1>
						<p>
							Since its existence, the village has made every effort for the
							public benefit, or for the benefit of others without compensation,
							whether it is an individual work, private or legal. It performed
							his humanitarian role as endowments or in-kind donations in
							support of a health or cultural activity, or the appeal of a free
							prisoner or a weak stricken aid.
							<br />
							The habit of support and direct acts of assistance to people with
							inherited needs in the village spontaneously prompted the
							establishment of successive charitable committees since the
							beginning of the last century with the residents of the village,
							residents, expatriates, and jealous people.
							<br />
							The construction of the primary school in 1948 AD.
							<br />
							- Building and paving the roads and streets of the village through
							popular action in 1960 AD.
							<br />
							- In 1965, the people of the village residing in the village and
							the city initiated the establishment of a charitable association
							known as: Rural Revival Association in the village of Al Mashtaya
							(charitable, social, urban, cultural ....) under No. 819 dated
							24/1/1965 AD issued by the Ministry of Affairs The social and work
							center is located in the village of Al-Mashtaya. It includes a
							general assembly of 85 members and an elected board of directors
							consisting of: a president, a deputy director, a secretary, a
							treasurer, three members.
							<br />
							It is associated with a charitable activity carried out by the
							expatriate residents of the village in America, Australia and
							other countries, with many charitable projects dominated by the
							urban service character, among its achievements:
							<br />
							- Supervises the issuance of a special annual calendar in the name
							of the association, in which the features of the village and its
							cooperative role are highlighted.
							<br />
							- Looking forward to an ambitious role in linking the people of
							the village to each other in the homeland, residents and
							expatriates, and fortifying their national human heritage.
							<br />
							- It carries out various cultural and social activities that can
							be monitored in its final budget, which is officially certified
							every year by a chartered accountant according to the attached
							form.
							<br />
							- The construction of the middle school in 1970 AD (currently a
							municipal building, a dispensary, an oven, a school hall, a
							nursery ....)
							<br />
							- Building a fence and a canopy for the cemetery in the village.
							<br />
							- Participate and contribute to the cleaning week held by the
							villagers on the first of August of each year, which is considered
							as the environment week.
							<br />
						</p>
					</div>
				</div>
				<br id='cat2' />
				<div
					style={{
						width: '100%',
						borderBottom: '1px solid #2a3a6f',
						marginBottom: '10px',
						marginTop: '10px',
					}}></div>
				<br />
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '0px 40px',
						}}>
						<h1 className='ar info-title'>أخوية القديسة كاترينا</h1>
					</div>
					<Carousel
						renderArrowNext={(onClick, hasNext) => (
							<Arrow onClick={onClick} direction={'Right'} hasNext={hasNext} />
						)}
						renderArrowPrev={(onClick, hasPrev) => (
							<Arrow onClick={onClick} direction={'Left'} hasPrev={hasPrev} />
						)}
						style={{ width: '500px' }}
						className='carousel'
						dynamicHeight
						autoPlay
						swipeable
						interval={3000}
						infiniteLoop>
						{getImagesCat2().map((value, index) => (
							<div className='carousel-img' key={`${index}-katrina-carousel`}>
								<img src={value} height='100%' alt='' className='main-img' />
							</div>
						))}
					</Carousel>
					<div className='info'>
						<p className='ar'>
							تأسست أخوية القديسة كاترينا للسيدات في قرية المشتاية عام 1997,
							بهدف النمو روحيا", وتأهيل العلاقات على أساس المحبة بين سيدات
							القرية, وكان لها نشاطات روحية وترفيهية, كالرحلات في ربوع سورية
							ولبنان, وزيارة الأديرة, ومع مرور الزمن, ولأن المحبة عطاء, اتسعت
							دائرة اهتمام الأخوية, واتجهت نحو الخدمة الاجتماعية والعمل الخيري,
							بدعم من المغتربين وفاعلي الخير من أبناء الوطن.
							<br />
							من أهم نشاطاتها, زيارة المرضى والمسنين, وتقديم المساعدات المادية
							والعينية, الإحتفال بعيد الأم وتكريم الأمهات, وكذلك بعيد المرأة,
							تكريم المسنين بحفل غذاء خاص يقام سنويا" وقد لاقى هذا النشاط
							تقديرا" كبيرا" من أهل القرية.
							<br />
							بلغ عمرها خمس وعشرين عاما", ولم توقفها الحرب على سورية, بل على
							العكس,نمت وضمت سيدات من حمص, وحلب, وادلب (اللواتي نزحن الى
							القرية), وتوسعت نشاطاتها, فساندت الدولة في دعم المدارس بالمحروقات,
							وتقديم مساعدة مادية لطلاب الشهادات والجامعات لتغطية جزء من تكاليف
							الدروس ووسائل النقل.
							<br />
							لها مشاركات في مهرجان القلعة والوادي ببازار يقام في سوق دير مار
							جرجس الأثري, كما قامت سيدات الأخوية بإعادة تدوير الأقمشة وذلك
							بإنتسابها إلى (محترف حنه) وخصصت حصتها من ربح القطع المباعة للأعمال
							الخيرية, والنظافة كانت لها حصة من نشاطاتها فقد قامت مٶخرا" بتوزيع
							براميل للقمامة في الشوارع الرئيسية للقرية.
							<br />
							أعجبت سيدات المغترب بهذه الأنشطة, فنقلت الفكرة إلى سيدات المشتاية
							في مدينة ألنتاون الأمريكية, وأسست أخوية مماثلة وسمينها بنفس الإسم,
							فكانت ابنه بارةَ لها وداعمة مادية كبيرة, ويعود لها الفضل بمشروع
							إنارة شوارع القرية كاملة بالطاقة البديلة (الشمسية).
							<br />
							وكذلك قامت بتوزيع قرطاسية كاملة مع حقائب مدرسية لكافة المراحل
							الدراسية.
							<br />
							وما زالت نشاطاتها مستمرة, وتسعى دوما" نحو الأفضل, لمنفعة أهل
							القرية, وستبقى مستمرة بإذن الله ما دامت المحبة عنوانها, والعطاء
							هدفها.
							<br />
						</p>
					</div>
				</div>
				<br id='cat3' />
				<div
					style={{
						width: '100%',
						borderBottom: '1px solid #2a3a6f',
						marginBottom: '10px',
						marginTop: '10px',
					}}></div>
				<br />
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '0px 40px',
						}}>
						<h1 className='ar info-title'>
							برنامج مهرجانات القلعة والوادي ٢٠٢٢
						</h1>
					</div>
					<div className='info'>
						<img
							className='carousel-img'
							style={{ objectFit: 'contain' }}
							src={EventPic1}
							alt='برنامج مهرجانات القلعة والوادي ٢٠٢٢'
						/>
					</div>
				</div>
				<br id='cat4' />
				<div
					style={{
						width: '100%',
						borderBottom: '1px solid #2a3a6f',
						marginBottom: '10px',
						marginTop: '10px',
					}}></div>
				<br />
				<h1 className='ar info-title'>أسبوع النظافة ٢٠٢٢</h1>
				<Carousel
					renderArrowNext={(onClick, hasNext) => (
						<Arrow onClick={onClick} direction={'Right'} hasNext={hasNext} />
					)}
					renderArrowPrev={(onClick, hasPrev) => (
						<Arrow onClick={onClick} direction={'Left'} hasPrev={hasPrev} />
					)}
					style={{ width: '500px' }}
					className='carousel'
					dynamicHeight
					autoPlay
					swipeable
					interval={3000}
					infiniteLoop>
					{getImagesCat3().map((value, index) => (
						<div className='carousel-img' key={`${index}-carousel`}>
							<img src={value} height='100%' alt='' className='main-img' />
						</div>
					))}
				</Carousel>
			</div>
		</div>
	);
}

export default Events;
