import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';

import Wildcard from './Views/Wildcard';
import Main from './Views/Main';
import Monastery from './Views/Monastery';
import TopBar from './Components/TopBar';
import Media from './Views/Media';
import Events from './Views/Events';
import People from './Views/People';
import Nature from './Views/Nature';
import Restaurants from './Views/Restaurants';
import Hotels from './Views/Hotels';
import ContactUs from './Views/ContactUs';
import Person from './Views/Person';
import PeopleData from './Content/People';

import Login from './Views/Admin/Login';
import Admin from './Views/Admin/Admin';
import AdminBar from './Views/Admin/Bar';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<div className="wrapper">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<TopBar />}>
						<Route index element={<Main />}></Route>
						<Route path="monastery" element={<Monastery />}></Route>
						<Route path="hotels" element={<Hotels />}></Route>
						<Route path="restaurants" element={<Restaurants />}></Route>
						<Route path="nature" element={<Nature />}></Route>
						<Route path="events" element={<Events />}></Route>
						<Route path="people">
							<Route index element={<People />}></Route>
							{Object.keys(PeopleData).map((val, index) => (
								<Route
									key={index}
									path={val}
									element={
										<Person
											name={PeopleData[val].name}
											img={PeopleData[val].img}
											content={PeopleData[val].content}
											otherImgs={PeopleData[val].otherImgs}
										/>
									}
								></Route>
							))}
						</Route>
						<Route path="media" element={<Media />}></Route>
						<Route path="contactus" element={<ContactUs />}></Route>
						<Route path="*" element={<Wildcard />}></Route>
					</Route>
					<Route path="admin">
						<Route element={<AdminBar />}>
							<Route index element={<Admin />}></Route>
						</Route>
						<Route path="login" element={<Login />}></Route>
					</Route>
				</Routes>
			</BrowserRouter>
		</div>
	</React.StrictMode>
);
