import React from 'react';
import { Link } from 'react-router-dom';
import PeopleData from '../Content/People';

function People() {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				paddingBottom: '50px',
			}}>
			<p className='writer' style={{padding: "10px"}}>
				من كتاب ( المشتاية - نهلة الظمآن ) للأستاذ رامز الياس صليبي 2009
			</p>
			<h1 style={{ padding: '10px 0px' }}>Table of important people</h1>
			{Object.keys(PeopleData).map((val, index) => (
				<Link
					style={{ paddingTop: '10px', fontSize: '18px' }}
					to={val}
					key={index}>
					{PeopleData[val].name}
				</Link>
			))}
		</div>
	);
}

export default People;
