import React from 'react';
import './Person.css';

function Person(props) {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignContent: 'center',
				padding: '40px 0px',
			}}>
			<img className='main-person-img' src={props.img} alt={props.name}></img>
			<h1 className='person-name'>{props.name}</h1>
			<p className='ar person-content'>{props.content}</p>

			{props.otherImgs.map((image, index) => (
				<img className='main-person-img' style={{paddingBottom: "20px"}} src={image} key={index}></img>
			))}
		</div>
	);
}

export default Person;
