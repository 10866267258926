import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { importAll } from '../Components/Helpers';
import Arrow from '../Components/CarouselArrow';

const req = require.context(
	'../Content',
	false,
	/Nature[0-9]+.(png|jpe?g|svg|JPG)$/
);
function getImages() {
	const images = importAll(req, true);
	let sortList = {};
	for (let key of Object.keys(images)) {
		const index = parseInt(key.split('.')[0].substring(6));
		sortList[index] = images[key];
	}
	const out = Object.values(sortList);
	return out;
}

function Nature() {
	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				padding: '30px',
				alignItems: 'center',
			}}>
			<h1 className='ar'>صور لعدد من المناظر الطبيعية في المشتاية</h1>
			<h1 style={{ paddingBottom: "20px" }}>Pictures of natural landscapes in AlMishtaya</h1>
			<Carousel renderArrowNext={(onClick, hasNext) => (<Arrow onClick={onClick} direction={"Right"} hasNext={hasNext} />)}
				renderArrowPrev={(onClick, hasPrev) => (<Arrow onClick={onClick} direction={"Left"} hasPrev={hasPrev} />)}
				style={{ width: '500px' }}
				className='carousel'
				dynamicHeight
				autoPlay
				swipeable
				interval={3000}
				infiniteLoop>
				{getImages().map((value, index) => (
					<div className='carousel-img' key={`${index}-carousel`}>
						<img src={value} height='100%' alt='' className='main-img' />
					</div>
				))}
			</Carousel>
		</div>
	);
}

export default Nature;

